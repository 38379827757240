.fundoHome {
  background-image: url('../../../../public/imagens/home/background.png');
  height: 100%;
  width: 100%;
}

.imgApontador {
  width: 22px;
  height: 33px;
}
