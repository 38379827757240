.imagemFundoBranco {
  background-image: url('../../public/imagens/agendamento/background.png');
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
}

.fotoDentista {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Ajuste a altura conforme necessário */
  width: 200px;
  /* Ajuste a largura conforme necessário */
  border-radius: 50%;
  /* Torna a borda redonda */
  overflow: hidden;
  /* Garante que a imagem não ultrapasse os limites da máscara */
}

.fotoDentistaBotao {
  position: relative;
  top: 30px;
  left: 170px;
  /* Ajuste a distância inferior conforme necessário */

  /* Ajuste a distância à direita conforme necessário */
  background: none;
  border: none;
  cursor: pointer;
}

.imgResponsive {
  width: 100%;
  max-width: 500px;
  padding: 0;
  margin: auto;
  text-align: center;

}

/*
.cabecalho {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 200px;
  right: 0px;
  overflow: hidden;
}

.conteudo {
  position: absolute;
  top: 200px;
  bottom: 200px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.rodape {
  position: absolute;
  bottom: 0px;
  height: 200px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}
*/

/*
<html><body style="height:100%; width:100%"><div id="header" style="position:absolute; top:0px; left:0px; height:200px; right:0px;overflow:hidden;"></div><div id="content" style="position:absolute; top:200px; bottom:200px; left:0px; right:0px; overflow:auto;"></div><div id="footer" style="position:absolute; bottom:0px; height:200px; left:0px; right:0px; overflow:hidden;"></div></body></html>
*/